import React, { Component, Fragment } from "react"

import { withAuth0 } from "@auth0/auth0-react"
import { injectIntl } from "react-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Loading from "../components/loading"

class LoginPage extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { auth0 } = this.props
    auth0.loginWithRedirect()
  }

  render() {
    const { intl } = this.props
    const pageTitle = intl.formatMessage({ id: "Login" })
    return (
      <Layout pageTitle={pageTitle} location={this.props.location}>
        <SEO title={pageTitle} />
        <Loading />
      </Layout>
    )
  }
}

export default withAuth0(injectIntl(LoginPage))
